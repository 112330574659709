import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Header, Icon, List, Segment } from 'semantic-ui-react';

import type { UserWithProfile } from '@eeedo/types';
import type { FC } from 'react';

import * as styles from './InitialLoad.style';
import InitialLoadDescription from './InitialLoadDescription';
import InitialLoadIcon from './InitialLoadIcon';
import { logout, refreshToken } from 'src/actions/authActions';
import { fetchCaseImportTemplates } from 'src/actions/caseImportTemplateActions';
import { fetchCategories } from 'src/actions/categoryActions';
import { fetchChannelTypes } from 'src/actions/channelActions';
import { fetchChats } from 'src/actions/chatActions';
import { fetchEnvSettings, fetchFeatureFlags, fetchPhoneConfigurations } from 'src/actions/configActions';
import { fetchLinkLists } from 'src/actions/linkListActions';
import { fetchPriorities } from 'src/actions/priorityActions';
import { fetchResponseTemplates } from 'src/actions/responseTemplateActions';
import { fetchAutoSuggestions } from 'src/actions/suggestionActions';
import { fetchTags } from 'src/actions/tagActions';
import { fetchTicketPriorities, fetchTicketTypes, fetchTitleTemplates } from 'src/actions/ticketsActions';
import { fetchPersonalData, fetchRoles, fetchUsers } from 'src/actions/userActions';
import { fetchWebhooks } from 'src/actions/webhooksActions';
import { selectCurrentUser, selectIsCurrentUserAdmin } from 'src/selectors/rootStateSelectors';

import type { State } from 'src/types/initialState';

export type initialRequestsTypes =
  | 'users'
  | 'ticketTypes'
  | 'responseTemplates'
  | 'titleTemplates'
  | 'channelTypes'
  | 'linkLists'
  | 'personalData'
  | 'tags'
  | 'categories'
  | 'envSettings'
  | 'featureFlags'
  | 'roles'
  | 'caseImportTemplates'
  | 'priorities'
  | 'PhoneConfigurations'
  | 'chatStatuses'
  | 'PostIntegrationConfiguration'
  | 'autoSuggestions'
  | 'webhooks';

export interface InitialRequestState {
  type: initialRequestsTypes;
  isLoading: boolean;
  isCompleted: boolean;
  error: null | Error;
  adminRequired?: boolean;
  initialize: boolean;
}

interface InitialLoadProps {
  children: React.ReactNode;
}

const InitialLoad: FC<InitialLoadProps> = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showChildren, setShowChildren] = useState(false);

  const user = useSelector<State, UserWithProfile | undefined>(selectCurrentUser);
  const isAdmin = useSelector<State, boolean>(selectIsCurrentUserAdmin);
  const initialRequests = useSelector<State, InitialRequestState[]>((state) =>
    state.initialRequests.filter((req) => isAdmin || !req.adminRequired)
  );

  useEffect(() => {
    const accessTokenExpiration = localStorage.getItem('accessTokenExpiration');
    if (accessTokenExpiration) {
      dispatch(refreshToken(true));
    }

    // Dispatch all requests here!
    if (!user) {
      dispatch(fetchPersonalData());
      dispatch(fetchUsers());
    }

    if (user) {
      dispatch(fetchEnvSettings());
      dispatch(fetchFeatureFlags());
      dispatch(fetchTicketTypes());
      dispatch(fetchAutoSuggestions());
      dispatch(fetchChannelTypes());
      dispatch(fetchResponseTemplates());
      dispatch(fetchTitleTemplates());
      dispatch(fetchLinkLists());
      dispatch(fetchTags());
      dispatch(fetchCategories());
      dispatch(fetchChats());
      dispatch(fetchRoles());
      dispatch(fetchTicketPriorities());
      dispatch(fetchCaseImportTemplates());
      dispatch(fetchPriorities());
      dispatch(fetchPhoneConfigurations());

      if (isAdmin) {
        dispatch(fetchWebhooks());
      }
    }
  }, [user]);

  const loadOnClick = useCallback(() => setShowChildren(true), []);
  const logoutOnClick = useCallback(() => dispatch(logout({ type: 'manual' })), []);

  const sortStatuses = (a: InitialRequestState, b: InitialRequestState) => (a.type > b.type ? 1 : -1);
  const sortedRequests = useMemo(() => [...initialRequests].sort(sortStatuses), [initialRequests]);

  const completedRequests = initialRequests.filter((reqStatus) => reqStatus.isCompleted);
  const hasErrors = initialRequests.some((reqStatus) => reqStatus.error !== null);
  const allHasCompleted = completedRequests.length === initialRequests.length;

  if (showChildren === true || allHasCompleted) {
    return children;
  }

  return (
    <Segment style={styles.wrapper} className="intialLoad">
      <Grid>
        <Grid.Row columns="equal">
          <Grid.Column>
            <div className="logo" style={styles.logo} />
          </Grid.Column>
          <Grid.Column verticalAlign="middle">
            <Header align="center" as="h2" style={styles.header}>
              {t('EEEDO_CUSTOMER_SERVICE_MODULE')}
              <Header.Subheader style={styles.subheader}>
                {import.meta.env.VITE_CONTAINER_IMAGE}
                <br />
                {hasErrors && t('INITIAL_LOAD_ERRORS')}
                {!hasErrors && t('INITIAL_LOAD_LOADING_FILES')}
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <List relaxed style={styles.list}>
        {sortedRequests.map((initialRequest: InitialRequestState) => (
          <List.Item key={initialRequest.type}>
            <InitialLoadIcon initialRequest={initialRequest} />
            <List.Content>
              <List.Header>{t('INIT_LOAD_TITLE_' + initialRequest.type)}</List.Header>
              <List.Description>
                <InitialLoadDescription isAdmin={isAdmin} initialRequest={initialRequest} />
              </List.Description>
            </List.Content>
          </List.Item>
        ))}
      </List>
      {hasErrors && (
        <div style={styles.error}>
          <p>
            <Icon name="warning sign" />
            {t('INITIAL_LOAD_DESCRIPTION_RECOMMEND_LOGOUT')}
          </p>
        </div>
      )}
      <div className="initialLoadBtnContainer" style={styles.btnContainer}>
        <Button negative onClick={loadOnClick} labelPosition="left" icon>
          <Icon name="warning sign" />
          {t('INIT_LOAD_CONTINUE_ANYWAY')}
        </Button>
        <Button
          icon
          primary
          floated="right"
          labelPosition="left"
          className="initialLoadLogOutBtn"
          onClick={logoutOnClick}
        >
          <Icon name="log out" />
          {t('MAIN_TOPBAR_LOGOUT')}
        </Button>
      </div>
    </Segment>
  );
};

export default React.memo(InitialLoad);
